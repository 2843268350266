import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import Link from '../../kit/Link/Link';
import CheckboxIcon from '../../kit/icons/CheckboxIcon';
import { ErrorField } from '../MainModal/MainModal';
import { LoginModalState } from '../../redux/interfaces/userState.interface';
import { styles } from './styles';

interface MainModalProps {
  emailValue: string;
  surnameValue: string;
  nameValue: string;
  nameError: ErrorField;
  surnameError: ErrorField;
  setField: (field: string, value: string) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  emailError: ErrorField;
  passwordValue: string;
  passwordError: ErrorField;
  toggleView: (view: string) => void;
  loginModalState: LoginModalState;
  handleRegister: () => void;
  canRegister: boolean;
  setAgree: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function RegisterModal(props: MainModalProps) {
  const {
    emailValue,
    nameValue,
    surnameValue,
    nameError,
    surnameError,
    setField,
    onBlurHandler,
    emailError,
    passwordValue,
    passwordError,
    toggleView,
    loginModalState,
    handleRegister,
    canRegister,
    setAgree,
  } = props;
  const [visibilityPass, setVisibilityPass] = useState(false);
  useEffect(() => {
    if (!passwordValue) {
      setVisibilityPass(false);
    }
  }, [passwordValue]);
  return (
    <>
      <DialogTitle>
        <Box sx={styles.dialogInnerBox}>
          <>
            <Typography sx={styles.titleTypography} variant="desktopH3">
              Регистрация
            </Typography>
            <Box sx={styles.helperBox}>
              <Box sx={{ mr: 2 }}>
                <Typography variant="desktopP1" sx={styles.helperTypography1}>
                  Уже есть аккаунт?{' '}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={styles.helperTypography2}
                  onClick={() => toggleView('LOGIN')}
                >
                  Войти
                </Typography>
              </Box>
            </Box>
          </>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Grid container sx={{ my: 1 }}>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    sx={{ mb: 3, pr: 2, width: '100%' }}
                    value={nameValue}
                    error={true}
                    id="name"
                    label="Имя"
                    onBlur={onBlurHandler}
                    helperText={nameError.isError && nameError.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    onBlur={onBlurHandler}
                    sx={{ mb: 3, width: '100%' }}
                    error={true}
                    id="surname"
                    label="Фамилия"
                    value={surnameValue}
                    helperText={surnameError.isError && surnameError.message}
                  />
                </Grid>
              </Grid>
              {/* 
              <InputMask
                mask={
                  phoneValue.startsWith('+7')
                    ? '+9 999 999 99 99'
                    : '+999999999999999'
                }
                //@ts-ignore
                maskChar=" "
                alwaysShowMask
                value={phoneValue}
                onChange={(e) =>
                  setField(e.target.id, e.target.value.replace(/[\s]/g, ''))
                }
                onBlur={onBlurHandler}
              >
                {() => (
                  <TextField
                    variant="standard"
                    sx={{ my: 1 }}
                    placeholder="+799999999999"
                    id="phone"
                    error={true}
                    label="Телефон"
                    value={phoneValue}
                    helperText={phoneError.isError && phoneError.message}
                  />
                )}
              </InputMask> */}
            </Box>
            <TextField
              variant="standard"
              sx={{ my: 1 }}
              id="email"
              label="Email"
              error={true}
              placeholder="test@example.com"
              value={emailValue}
              fullWidth
              onChange={(e) => setField(e.target.id, e.target.value.trim())}
              onBlur={onBlurHandler}
              helperText={emailError.isError && emailError.message}
            />
            <Grid item sx={{ position: 'relative' }}>
              <TextField
                variant="standard"
                sx={{ my: 1 }}
                type={!visibilityPass ? 'password' : ''}
                id="password"
                error={true}
                label="Пароль"
                placeholder="*******"
                fullWidth
                value={passwordValue}
                onChange={(e) => setField(e.target.id, e.target.value)}
                onBlur={onBlurHandler}
                helperText={passwordError.isError && passwordError.message}
              />
              {visibilityPass ? (
                <VisibilityIcon
                  sx={styles.icon(visibilityPass)}
                  onClick={() =>
                    setVisibilityPass((prev) => (passwordValue ? !prev : prev))
                  }
                />
              ) : (
                <VisibilityOffIcon
                  sx={styles.icon(visibilityPass)}
                  onClick={() =>
                    setVisibilityPass((prev) => (passwordValue ? !prev : prev))
                  }
                />
              )}
            </Grid>
            <Box sx={styles.checkboxBox}>
              <Box>
                <Checkbox
                  color="primary"
                  checkedIcon={<CheckboxIcon />}
                  onChange={setAgree}
                />
              </Box>
              <Box>
                <Typography sx={styles.agreeTypography}>
                  Я соглашаюсь с{' '}
                  <Link
                    to="https://elbrusboot.camp/privacy-policy/"
                    target="_blank"
                    sx={{ color: 'kit.text.h' }}
                  >
                    Политикой конфиденциальности →
                  </Link>{' '}
                  и&nbsp;условиями{' '}
                  <Link
                    to="/docs/loyalty_program_elbrus_03_25.pdf"
                    target="_blank"
                    sx={{ color: 'kit.text.h' }}
                  >
                    Программы лояльности →
                  </Link>
                </Typography>
              </Box>
            </Box>
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={styles.actionsBox}>
          {loginModalState.isLoading && <CircularProgress />}
          {!loginModalState.isLoading && (
            <Button
              disabled={!canRegister}
              sx={{ width: 197, height: 41 }}
              variant="kitPrimary"
              onClick={handleRegister}
            >
              Зарегистрироваться
            </Button>
          )}
        </Box>
      </DialogActions>
    </>
  );
}
