import React from 'react';
import { Box, Container, Typography } from '@mui/material';

import styles, { createTitleColorTheme } from './styles';
import Link from '../../kit/Link/Link';

interface SectionProps {
  children?: any;
  title?: string;
  subtitle?: string;
  theme?: string;
  wrapperStyles?: any;
  anchor?: string;
  flexGrow?: string;
  titleSx?: any;
  titleH1?: string;
}

function Section(props: SectionProps) {
  const {
    children,
    title,
    // subtitle,
    theme = 'default',
    wrapperStyles = {},
    anchor = null,
    flexGrow = false,
    titleSx,
    titleH1 = false,
  } = props;

  return (
    <Box
      key={`section-${title}`}
      sx={{
        ...styles.wrapper,
        ...wrapperStyles,
        flexGrow: flexGrow && 1,
      }}
    >
      <Box
        component="p"
        key={anchor}
        id={anchor}
        sx={{ top: '-120px', position: 'relative', visibility: 'hidden' }}
      />
      <Container maxWidth="xl" sx={styles.container}>
        <Box sx={titleSx}>
          {title && (
            <Typography
              variant="desktopH3"
              sx={{
                ...styles.title,
                ...createTitleColorTheme(theme),
              }}
              {...(titleH1 ? { component: 'h1' } : {})}
            >
              {Array.isArray(title)
                ? title.map((item) => (
                    <Typography key={item}>{item}</Typography>
                  ))
                : title}
            </Typography>
          )}
          <Link
            target="_blank"
            to={'/docs/loyalty_program_elbrus_03_25.pdf'}
            variant="kitPrimary"
            sx={{ fontSize: 14, pt: { xs: 0, lg: 3 }, zIndex: 1 }}
          >
            PDF с условиями программы
          </Link>
        </Box>
        {children}
      </Container>
    </Box>
  );
}

export default Section;
