import { Box, Container, Grid, Stack } from '@mui/material';
import MapPinIcon from '../../../kit/icons/MapPinIcon';
import Skolkovo from '../../../kit/icons/Skolkovo';
import styles from './styles';
import Link from '../../../kit/Link/Link';
import { VkOutlinedIcon } from '../../../kit/icons/VkOutlinedIcon';
import { YouTubeOutlinedIcon } from '../../../kit/icons/YouTubeOutlinedIcon';
import { TelegramOutlinedIcon } from '../../../kit/icons/TelegramOutlinedIcon';

const contactLinks = [
  [
    {
      title: '+7 (499) 938-68-24',
      to: 'tel:+74999386824',
    },
  ],
  [
    {
      title: 'Чат в whatsApp',
      to: 'https://wa.me/74957860581',
    },
    {
      title: 'Telegram',
      to: 'https://t.me/elbrus_bootcamp',
    },
  ],
  [
    {
      title: 'info@elbrusboot.camp',
      to: 'mailto:info@elbrusboot.camp',
    },
  ],
];

const navigationLinks = [
  {
    title: 'О буткемпе',
    to: 'https://elbrusboot.camp/about/',
  },

  {
    title: 'Программы',
    to: 'https://elbrusboot.camp/#program',
  },

  {
    title: 'Бесплатные мероприятия',
    to: 'https://elbrusboot.camp/events/',
  },
  {
    title: 'Статьи для новичков',
    targetBlank: true,
    to: 'https://elbrusboot.camp/blog/',
  },
  {
    title: 'Контакты',
    to: 'https://elbrusboot.camp/contacts/',
  },
];

const iconLinks = [
  {
    to: 'https://vk.com/elbrusbootcamp',
    IconComponent: VkOutlinedIcon,
  },
  {
    to: 'https://www.youtube.com/channel/UCaKZxz2d1KI-wN3l6fX7zdQ/featured',
    IconComponent: YouTubeOutlinedIcon,
  },
  {
    to: 'https://t.me/elbrusbootcamp1',
    IconComponent: TelegramOutlinedIcon,
  },
];
const legalLinks = [
  {
    title: 'PDF с условиями программы лояльности',
    to: '/docs/loyalty_program_elbrus_03_25.pdf',
  },
  {
    title: 'Вакансии',
    to: 'https://elbrus-bootcamp.notion.site/f497bf61afdb4f87a0004bf36666a46d?v=e4b07fe9f9364757bff5f92cd346a5bc',
    targetBlank: true,
  },
];

export default function Footer() {
  return (
    <Box sx={styles.footerWrapper}>
      <Container
        maxWidth="xl"
        component="footer"
        sx={{
          padding: {
            xs: '0 16px',
            lg: '0 60px',
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={4} sx={styles.firstColumn}>
            <Stack>
              {contactLinks.map((linkDesc, index) => (
                <Box key={`footer-${index}`} sx={styles.linkWrapper}>
                  {linkDesc.map((item) => (
                    <Box key={item.to} component="span" sx={styles.linkItem}>
                      <Link
                        to={item.to}
                        variant="kitSecondaryInverseWhite"
                        target="_blank"
                        sx={styles.link}
                      >
                        {item.title}
                      </Link>
                    </Box>
                  ))}
                </Box>
              ))}
            </Stack>
            <Stack direction="column" sx={styles.addressContainer}>
              <Box sx={styles.mapIconContainer}>
                <MapPinIcon />
              </Box>
              <Link
                to="https://www.google.com/maps/place/Elbrus+Coding+Bootcamp+%7C+Школа+программирования+Донской+район/@55.7065807,37.5926111,16.25z/data=!4m13!1m6!3m5!1s0x46b54bbba5624251:0x42cefa28cf581f67!2zRWxicnVzIENvZGluZyBCb290Y2FtcCB8INCo0LrQvtC70LAg0L_RgNC-0LPRgNCw0LzQvNC40YDQvtCy0LDQvdC40Y8g0JTQvtC90YHQutC-0Lkg0YDQsNC50L7QvQ!8m2!3d55.706541!4d37.5970071!3m5!1s0x46b54bbba5624251:0x42cefa28cf581f67!8m2!3d55.706541!4d37.5970071!15sChZFbGJydXMgQ29kaW5nIEJvb3RjYW1wWjAKFmVsYnJ1cyBjb2RpbmcgYm9vdGNhbXAiFmVsYnJ1cyBjb2RpbmcgYm9vdGNhbXCSARBlZHVjYXRpb25fY2VudGVy"
                variant="kitSecondaryInverseWhite"
                target="_blank"
                sx={styles.mapInfo}
              >
                Москва, ул. Орджоникидзе, 11 стр. 10
                <br />
                (м. Ленинский проспект)
              </Link>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4} sx={styles.secondColumn}>
            <Stack>
              {navigationLinks.map((linkDesc) => (
                <Box key={linkDesc.to} sx={styles.linkWrapper}>
                  <Link
                    to={linkDesc.to}
                    variant="kitSecondaryInverseWhite"
                    sx={{ ...styles.link }}
                    target={linkDesc.targetBlank ? '_blank' : '_self'}
                  >
                    {linkDesc.title}
                  </Link>
                </Box>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4} sx={styles.thirdColumn}>
            <Stack>
              {legalLinks.map((linkDesc) => (
                <Box key={linkDesc.to} sx={styles.linkWrapper}>
                  <Link
                    to={linkDesc.to}
                    variant="kitSecondaryInverseWhite"
                    sx={{ ...styles.link }}
                    target="_blank"
                  >
                    {linkDesc.title}
                  </Link>
                </Box>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4} sx={styles.fourthColumn}>
            <Link to="https://navigator.sk.ru/orn/1123979" target="_blank">
              <Skolkovo sx={styles.logo} />
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={styles.fifthColumn}></Grid>
          <Grid item xs={12} md={6} lg={4} sx={styles.sixthColumn}>
            <Stack direction="row" spacing={3} sx={styles.social}>
              {iconLinks.map(({ to, IconComponent }) => (
                <Link
                  key={to}
                  to={to}
                  variant="kitSecondaryInverseWhite"
                  target="_blank"
                  sx={styles.socialIcon}
                >
                  <IconComponent sx={styles.icon} />
                </Link>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
